// import * as React from 'react'
import { AuthContextProvider } from '@/context/auth-context'
import { ConfigContextProvider } from '@/context/config-context'
import { useAuthService } from '@/services/auth.service'
import { useConfigService } from '@/services/config.service'
import { CacheProvider, EmotionCache } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
// Import the styles provided by the react-pdf-viewer packages
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { Router } from 'next/router'
import theme from '../components/layout/theme'
import createEmotionCache from '../createEmotionCache'

// ** Global css styles
import '../styles/globals.css'

import { Box, LinearProgress } from '@mui/material'
import { useState } from 'react'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
  Component: NextPage
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  const authService = useAuthService()
  const configService = useConfigService()
  const [isLoadingPage, setLoadingPage] = useState(false)

  // ** Pace Loader
  Router.events.on('routeChangeStart', () => {
    setLoadingPage(true)
  })
  Router.events.on('routeChangeError', () => {
    setLoadingPage(false)
  })
  Router.events.on('routeChangeComplete', () => {
    setLoadingPage(false)
  })

  // Variables
  const getLayout = Component.getLayout ?? ((page) => <>{page}</>)

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Livelong panel</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <ConfigContextProvider value={configService}>
          <AuthContextProvider value={authService}>
            {
              <>
                <Box sx={{ width: '100%' }}>
                  {isLoadingPage && <LinearProgress sx={{ zIndex: 9999 }} color="info" />}
                  {getLayout(<Component {...pageProps} />)}
                </Box>
              </>
            }
          </AuthContextProvider>
        </ConfigContextProvider>
      </ThemeProvider>
    </CacheProvider>
  )
}
